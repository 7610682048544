import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

/**
 * Header and navigation hooks
 */
export class Nav {
    constructor() {
        // Elements
        this.nav = document.querySelector('.js-nav');
        this.navList = document.querySelector('.js-nav .nav-list');
        this.navToggle = document.querySelector('.js-nav-toggle');
        this.header = document.querySelector('.js-header');
        this.search = document.querySelector('.js-nav-search');

        if (!this.nav || !this.navToggle) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        // Main nav toggle
        this.navToggle.addEventListener('click', () => {
            this.toggle();

            if (this.navToggle.classList.contains('is-active')) {
                disableBodyScroll(this.navList);
                document.documentElement.style.overflow = 'hidden';
            } else {
                enableBodyScroll(this.navList);
                document.documentElement.style.removeProperty('overflow');
            }
        });

        if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
        }

        window.addEventListener('resize', () => {
            if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
                document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
            }

            if (window.innerWidth >= 830 && this.nav.classList.contains('is-active')) {
                this.toggle();
            }
        });

        window.addEventListener('scroll', () => {
            if (this.header) {
                if (window.pageYOffset > 0) {
                    this.header.classList.add('is-scrolled');
                } else {
                    this.header.classList.remove('is-scrolled');
                }
            }
        });

        this.search.addEventListener('focusin', () => {
            this.search.classList.add('has-focus-within');
        });

        this.search.addEventListener('focusout', () => {
            this.search.classList.remove('has-focus-within');
        });
    }

    // Handles a timed animation with different enter/exit styles
    toggle() {
        this.nav.classList.toggle('is-active');
        this.navToggle.classList.toggle('is-active');
        document.querySelector('body').classList.toggle('has-active-nav');

        // Handle the animation
        if (!this.navToggle.classList.contains('is-active')) {
            this.navToggle.classList.add('is-exiting');

            const animationEndHandler = () => {
                this.navToggle.classList.remove('is-exiting');
                this.navToggle.removeEventListener('animationend', animationEndHandler);
            };
            this.navToggle.addEventListener('animationend', animationEndHandler);
        }
    }
}
