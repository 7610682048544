import 'js-cookie';

/**
 * Header and navigation hooks
 */
export class CookiesNotice {
    constructor() {
        // Elements
        this.cookiesNotice = document.querySelector('.js-cookies');
        this.cookiesDismissBtns = document.querySelectorAll('.js-cookies-dismiss');

        if (!this.cookiesNotice || !this.cookiesDismissBtns.length) {
            return;
        }

        if (window.Cookies.get('cookies') !== 'dismissed') {
            this.cookiesNotice.classList.add('is-active');
        }

        this.addEventListeners();
    }

    addEventListeners() {
        this.cookiesDismissBtns.forEach((dismissBtn) => {
            dismissBtn.addEventListener('click', () => {
                window.Cookies.set('cookies', 'dismissed', { expires: 7 });
                this.cookiesNotice.classList.remove('is-active');
            });
        });
    }
}
