'use strict';

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';
import smoothscroll from './vendor/smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
import './utilities/polyfills';
import Swiper, { A11y, Navigation } from 'swiper';
import { Accordion } from './modules/accordion';
import { Focus } from './modules/focus';
import { Nav } from './modules/nav';
import { GritEvents } from './modules/grit-events.js';
import { CookiesNotice } from './modules/cookies';
import MagicGrid from 'magic-grid';
import flatpickr from 'flatpickr';


Swiper.use([A11y, Navigation]);

class App {
    constructor() {
        smoothscroll.polyfill();

        this.cookiesNotice = new CookiesNotice();
        this.focus = new Focus();
        this.gritEvents = new GritEvents();
        this.nav = new Nav();

        this.swipers = [];
        const swipers = document.querySelectorAll('.js-swiper');
        swipers.forEach((swiper) => {
            const nextEl = swiper.querySelector('.swiper-button-next');
            const prevEl = swiper.querySelector('.swiper-button-prev');

            this.swipers.push(new Swiper(swiper, {
                a11y: {
                    enabled: true,
                },
                loop: true,
                navigation: {
                    nextEl,
                    prevEl,
                },
                noSwipingSelector: 'a, button',
                touchEventsTarget: 'wrapper',
                on: {
                    slideChange(swiper) {
                        const prevWrapper = prevEl.closest('.hero-prev');
                        const nextWrapper = nextEl.closest('.hero-next');

                        let prevSlide;
                        if (!swiper.isBeginning) {
                            prevSlide = swiper.slides[swiper.activeIndex - 1];
                        } else {
                            prevSlide = swiper.slides[swiper.slides.length - 3];
                        }

                        const prevSlideTitle = prevSlide.querySelector('.js-hero-title');
                        prevWrapper.querySelector('.js-hero-prev-title').innerHTML = prevSlideTitle.textContent;

                        const prevSlideLabel = prevSlide.querySelector('.js-hero-label').dataset.labelPrevNext ? prevSlide.querySelector('.js-hero-label').dataset.labelPrevNext : prevWrapper.querySelector('.js-hero-prev-label').dataset.labelDefault;
                        if (prevSlideLabel) {
                            prevWrapper.querySelector('.js-hero-prev-label').innerHTML = prevSlideLabel;
                        }

                        let nextSlide;
                        if (!swiper.isEnd) {
                            nextSlide = swiper.slides[swiper.activeIndex + 1];
                        } else {
                            nextSlide = swiper.slides[2];
                        }

                        const nextSlideTitle = nextSlide.querySelector('.js-hero-title');
                        nextWrapper.querySelector('.js-hero-next-title').innerHTML = nextSlideTitle.textContent;

                        const nextSlideLabel = nextSlide.querySelector('.js-hero-label').dataset.labelPrevNext ? nextSlide.querySelector('.js-hero-label').dataset.labelPrevNext : nextWrapper.querySelector('.js-hero-next-label').dataset.labelDefault;
                        if (nextSlideLabel) {
                            nextWrapper.querySelector('.js-hero-next-label').innerHTML = nextSlideLabel;
                        }
                    },
                },
            }));
        });

        this.eventSwipers = [];
        const eventSwipers = document.querySelectorAll('.js-swiper-events');
        eventSwipers.forEach((eventSwiper) => {
            const nextEl = eventSwiper.querySelector('.swiper-button-next');
            const prevEl = eventSwiper.querySelector('.swiper-button-prev');

            this.eventSwipers.push(new Swiper(eventSwiper, {
                a11y: {
                    enabled: true,
                },
                autoHeight: true,
                loop: true,
                navigation: {
                    nextEl,
                    prevEl,
                },
                noSwipingSelector: 'a, button',
                touchEventsTarget: 'wrapper',
                on: {
                    slideChange(eventSwiper) {
                        const prevWrapper = prevEl.closest('.hero-prev');
                        const nextWrapper = nextEl.closest('.hero-next');

                        let prevSlide;
                        if (!eventSwiper.isBeginning) {
                            prevSlide = eventSwiper.slides[eventSwiper.activeIndex - 1];
                        } else {
                            prevSlide = eventSwiper.slides[eventSwiper.slides.length - 1];
                        }

                        if (typeof(document.querySelector('.js-upcoming-events-prev-title')) != 'undefined' && document.querySelector('.js-upcoming-events-prev-title') != null) {
                            const prevSlideTitle = prevSlide.querySelector('.js-upcoming-event');
                            prevWrapper.querySelector('.js-upcoming-events-prev-title').innerHTML = prevSlideTitle.textContent;
                        }

                        let nextSlide;
                        if (!eventSwiper.isEnd) {
                            nextSlide = eventSwiper.slides[eventSwiper.activeIndex + 1];
                        } else {
                            nextSlide = eventSwiper.slides[0];
                        }

                        if (typeof(document.querySelector('.js-upcoming-events-next-title')) != 'undefined' && document.querySelector('.js-upcoming-events-next-title') != null) {
                            const nextSlideTitle = nextSlide.querySelector('.js-upcoming-event');
                            nextWrapper.querySelector('.js-upcoming-events-next-title').innerHTML = nextSlideTitle.textContent;
                        }
                    },
                },
            }));
        });

        this.filterSelects = document.querySelectorAll('.js-filter-select');
        this.filterSelects.forEach((filterSelect) => {
            filterSelect.addEventListener('change', () => {
                window.location = filterSelect.value;
            });
        });

        this.accordions = [];
        const accordions = document.querySelectorAll('.js-accordion');
        accordions.forEach((accordion) => {
            this.accordions.push(new Accordion(accordion));
        });

        window.addEventListener('load', () => {
            window.dispatchEvent(new CustomEvent('scroll'));
            window.dispatchEvent(new CustomEvent('resize'));
        });

        // Masonry
        if (typeof(document.querySelector('.event-item')) != 'undefined' && document.querySelector('.event-item') != null) {
            const magicGrid = new MagicGrid({
                container: '.event-list',
                static: true,
                gutter: 30,
            });

            magicGrid.listen();
        }

        // Flatpickr
        if (typeof(document.querySelector('.input-datepicker')) != 'undefined' && document.querySelector('.input-datepicker') != null) {
            flatpickr('.input-datepicker', {
                mode : 'range',
                minDate : 'today',
                dateFormat : 'd-m-Y',
                wrap : true,
                onClose : function(selectedDates, dateStr, instance) {
                    if(selectedDates.length == 1) {
                        instance.setDate([selectedDates[0],selectedDates[0]], true);
                    }
                },
            });
        }
    }
}

new App();
