/**
 * Toggle focus styles on when users are tabbing, and turn them off once
 * they begin clicking again
 */
export class Focus {
    constructor() {
        this.addEventListeners();
    }

    addEventListeners() {
        const handleFirstTab = (e) => {
            if (e.keyCode === 9) {
                document.body.classList.add('has-tabbing-user');

                window.removeEventListener('keydown', handleFirstTab);
                window.addEventListener('mousedown', handleMouseDownOnce);
            }
        };

        const handleMouseDownOnce = () => {
            document.body.classList.remove('has-tabbing-user');

            window.removeEventListener('mousedown', handleMouseDownOnce);
            window.addEventListener('keydown', handleFirstTab);
        };

        window.addEventListener('keydown', handleFirstTab);
    }
}
