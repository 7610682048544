export class Accordion {
    constructor(element) {
        this.settings = {
            anim: {
                duration: 300,
                easeInOut: 'ease-in-out',
            },
        };

        // Elements
        this.accordion = element;
        this.toggle = this.accordion.querySelector('.js-accordion-toggle');
        this.body = this.accordion.querySelector('.js-accordion-body');

        if (!this.toggle || !this.body) {
            return;
        }

        // Handle animation frame state
        this.lastRAF = null;
        this.isAnimating = false;

        this.addEventListeners();
    }

    addEventListeners() {
        this.toggle.addEventListener('click', () => {
            if (this.isAnimating) {
                return;
            }

            this.handleToggle();
        });
    }

    handleToggle() {
        this.accordion.classList.toggle('is-active');
        this.toggle.classList.toggle('is-active');
        this.isAnimating = true;
        // document.querySelector('body').classList.toggle('has-active-nav');

        // Handle the animation
        if (this.accordion.classList.contains('is-active')) {
            this.body.classList.add('is-active');
            this.body.style.transition = `height ${this.settings.anim.duration}ms ${this.settings.anim.easeInOut}`;
            this.body.style.height = '0px';

            const bodyHeight = this.body.scrollHeight;

            this.lastRAF = requestAnimationFrame(() => {
                this.body.style.height = `${bodyHeight}px`;

                const transitionEnd = (e) => {
                    if (e.target === this.body) {
                        this.body.removeAttribute('style');
                        this.body.removeEventListener('transitionend', transitionEnd);
                        this.isAnimating = false;
                    }
                };

                this.body.addEventListener('transitionend', transitionEnd);
                this.lastRAF = null;
            });
        } else {
            this.body.style.transition = `height ${this.settings.anim.duration}ms ${this.settings.anim.easeInOut}`;
            this.body.style.height = `${this.body.scrollHeight}px`;

            this.lastRAF = requestAnimationFrame(() => {
                this.body.style.height = '0px';

                const transitionEnd = (e) => {
                    if (e.target === this.body) {
                        this.body.removeAttribute('style');
                        this.body.removeEventListener('transitionend', transitionEnd);
                        this.body.classList.remove('is-active');
                        this.isAnimating = false;
                    }
                };

                this.body.addEventListener('transitionend', transitionEnd);
                this.lastRAF = null;
            });
        }
    }
}
