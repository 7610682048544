/**
 * Polyfill missing methods for older browsers
 */
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (selector) {
        let el = this;

        do {
            if (el.matches(selector)) {
                return el;
            }

            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
    };
}

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


if (!('classList' in SVGElement.prototype)) {
    Object.defineProperty(SVGElement.prototype, 'classList', {
        get() {
            return {
                contains: (className) => {
                    return this.className.baseVal.split(' ').indexOf(className) !== -1;
                },
                add: (className) => {
                    return this.setAttribute('class', this.getAttribute('class') + ' ' + className);
                },
                remove: (className) => {
                    const removedClass = this.getAttribute('class').replace(new RegExp('(\\s|^)' + className + '(\\s|$)', 'g'), '$2');
                    if (this.classList.contains(className)) {
                        this.setAttribute('class', removedClass);
                    }
                },
            };
        },
    });
}

if (!String.prototype.repeat) {
    String.prototype.repeat = function (count) {
        'use strict';
        if (this == null) {
            throw new TypeError('can\'t convert ' + this + ' to object');
        }

        let str = '' + this;
        // To convert string to integer.
        count = +count;

        // Check NaN
        if (count != count) {
            count = 0;
        }

        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }

        if (count == Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }

        count = Math.floor(count);
        if (str.length == 0 || count == 0) {
            return '';
        }

        // Ensuring count is a 31-bit integer allows us to heavily optimize the
        // main part. But anyway, most current (August 2014) browsers can't handle
        // strings 1 << 28 chars or longer, so:
        if (str.length * count >= 1 << 28) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }

        const maxCount = str.length * count;
        count = Math.floor(Math.log(count) / Math.log(2));

        while (count) {
            str += str;
            count--;
        }
        str += str.substring(0, maxCount - str.length);
        return str;
    };
}


/**
 * Detect IE 11
 */
if (!(window.ActiveXObject) && 'ActiveXObject' in window) {
    document.documentElement.classList.add('ie11');
}

Number.isNaN = Number.isNaN || function isNaN(input) {
    return typeof input === 'number' && input !== input;
};
