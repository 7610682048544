/**
 * Toggle focus styles on when users are tabbing, and turn them off once
 * they begin clicking again
 */
export class GritEvents {
    constructor() {
        if (typeof ga === 'undefined' && typeof gtag === 'undefined') {
            console.warn('GA and/or GTAG undefined, check your analyics snippet has been added');

            return false;
        }

        this.addEventListeners();
    }

    /**
     * Fire the GA Event
     * @param {string} eventCategory
     * @param {string} eventAction
     * @param {string} eventLabel
     * @param {string} eventValue
     */
    trackEvent(eventCategory, eventAction, eventLabel, eventValue) {
        if (typeof gtag !== 'undefined') {
            window.gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'value': eventValue,
            });
        } else if (typeof ga !== 'undefined') {
            window.ga('send', 'event', {
                eventCategory: eventCategory,
                eventAction: eventAction,
                eventLabel: eventLabel,
                eventValue: eventValue,
            });
        }
    }


    /**
     * Select closest parent with polyfill
     * @param  {Object} el       element to search parents of
     * @param  {string} selector parent selector to search for
     * @return {Object}          closest element
     */
    closest(el, selector) {
        let matchesFn;

        // find vendor prefix
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some((fn) => {
            if (typeof document.body[fn] === 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }


    /**
     * Find an element's location or approximate sectioning tag
     * @param  {Object} el
     * @return {string}
     */
    pagePosition(el) {
        if (typeof jQuery !== 'undefined' && el instanceof window.jQuery === true) {
            el = el[0];
        }

        // Set default no data string
        let position = 'No page location data';

        // Look for closest ancestor with 'data-event-position' attr
        const ancestor = this.closest(el, '[data-event-position]');

        if (ancestor) {
            position = ancestor.getAttribute('data-event-position');
        } else {
            // If not found, go by approximation
            const approxAncestor = this.closest(el, 'header, main, nav, footer');

            if (approxAncestor) {
                position = '<' +  approxAncestor.tagName + '>';
            }
        }

        return position;
    }

    addEventListeners() {
        /**
         * Safely loop through NodeLists without using
         * [].forEach.call(NodeList) hack
         * @param {Array}    array
         * @param {Function} callback
         * @param {string}   scope
         */
        function forEach(array, callback, scope) {
            for (let i = 0; i < array.length; i++) {
                callback.call(scope, i, array[i]);
            }
        }


        /**
         * Social Platform links
         */
        const socialLinks = document.querySelectorAll('a[href*="facebook.com"], ' + 'a[href*="twitter.com"], a[href*="youtube.com"], a[href*="linkedin.com"], ' + 'a[href*="instagram.com"], a[href*="plus.google.com"]');

        forEach(socialLinks, (i, el) => {
            el.addEventListener('click', () => {
                this.trackEvent('Social Platform Link', 'Clicked', el.href + ' - ' + this.pagePosition(el));
            });
        });


        /**
         * Mailto links
         */
        const mailtoLinks = document.querySelectorAll('a[href^="mailto:"]');

        forEach(mailtoLinks, (i, el) => {
            el.addEventListener('click', () => {
                const email = el.href.replace('mailto:', '');
                this.trackEvent('Email Address', 'Clicked', email + ' - ' + this.pagePosition(el));
            });
        });


        /**
         * Tel links
         */
        const telLinks = document.querySelectorAll('a[href^="tel:"]');

        forEach(telLinks, (i, el) => {
            el.addEventListener('click', () => {
                const telephone = el.href.replace('tel:', '');
                this.trackEvent('Telephone Number', 'Clicked', telephone + ' - ' + this.pagePosition(el));
            });
        });


        /**
         * External Links
         */
        const extLinks = document.querySelectorAll('a[target="_blank"], a[rel="external"]');

        forEach(extLinks, (i, el) => {
            el.addEventListener('click', () => {
                this.trackEvent('External Link', 'Clicked', el.href);
            });
        });


        /**
         * Form submissions
         */
        const forms = document.querySelectorAll('form.is-submitted');

        forEach(forms, (i, el) => {
            this.trackEvent('Form', 'Submitted', el.getAttribute('data-form-name') + ' - ' + this.pagePosition(el));
        });
    }
}
